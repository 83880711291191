var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("display: flex; height: " + (_vm.display ? (_vm.products_error || _vm.vehicle_error ? '300px' : '100%') : '3rem') + "; width: 100%;"))},[_c('form',{class:_vm.$style.tool,on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{class:[_vm.$style.inputs, _vm.$style.row]},[_c('span',{class:_vm.$style.registration},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.registration),expression:"registration",modifiers:{"trim":true}}],class:_vm.shake ? _vm.$style.shake : '',attrs:{"type":"text","placeholder":"ABC-123","disabled":_vm.display},domProps:{"value":(_vm.registration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.registration=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),(_vm.display)?_c('button',{class:_vm.$style['margin-left'],attrs:{"title":"Tyhjennä haku","type":"button"},on:{"click":function($event){$event.preventDefault();_vm.display = false
          _vm.registration = ''}}},[_c('span',{class:_vm.display ? _vm.$style.cross : ''})]):_c('button',{class:_vm.$style['margin-left'],attrs:{"title":"Hae","type":"submit"}},[(_vm.waiting)?_c('span',{class:_vm.waiting ? _vm.$style.spinner : ''}):_c('span',[_vm._v("Hae")])])]),(_vm.display)?_c('div',{class:[_vm.$style.results, _vm.$style.column]},[_c('div',{class:[_vm.$style.tabs, _vm.$style['margin-top']]},[_c('button',{class:[
            _vm.$style.tab,
            _vm.$style.first,
            _vm.selected === 'first' ? _vm.$style.selected : ''
          ],attrs:{"title":"Ajoneuvoon sopivat tuotteet","type":"button"},on:{"click":function($event){_vm.selected = 'first'}}},[_vm._v(" Tuotteet ")]),_c('button',{class:[
            _vm.$style.tab,
            _vm.$style.second,
            _vm.selected === 'second' ? _vm.$style.selected : ''
          ],attrs:{"title":"Ajoneuvon perustiedot","type":"button"},on:{"click":function($event){_vm.selected = 'second'}}},[_vm._v(" Ajoneuvo ")])]),(_vm.selected === 'first')?_c('div',{class:_vm.$style.content},[(_vm.products_error)?_c('div',{class:_vm.$style['margin-bottom']},[(_vm.products_error_status === 404)?_c('div',[_vm._v(" Syystä tai toisesta emme onnistuneet määrittämään ajoneuvoosi sopivia tuotteita. Voit tästä huolimatta selata niitä kaupassamme. ")]):(_vm.products_error_status === 429)?_c('div',[_vm._v(" Hakuraja saavutettu. Yritä myöhemmin uudelleen. ")]):_c('div',[_vm._v("Jokin meni pieleen. Yritä myöhemmin uudelleen.")])]):_vm._e(),(_vm.products)?_c('div',_vm._l((Object.values(_vm.products)),function(ref,i){
          var description = ref.description;
          var value = ref.value;
return _c('div',{key:("value-" + i)},[_c('div',{class:_vm.$style.description},[_vm._v(" "+_vm._s(description)+" ")]),_vm._l((value),function(item,item_index){return _c('div',{key:("item-" + item_index),class:_vm.$style.value},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","margin-top":"1rem"}},[_c('div',{class:_vm.$style['product-container'],staticStyle:{"height":"2rem","display":"inline-flex","margin-top":"-0.5rem"}},[_c('span',{class:_vm.$style['product-name'],attrs:{"title":"Tuotteen nimi"}},[_vm._v(_vm._s(item.product_name + (item.quantity > 1 ? (" (" + (item.quantity) + " kpl)") : '')))]),_c('span',{class:_vm.$style['product-name'],staticStyle:{"font-weight":"normal","color":"black"},attrs:{"title":"Tuotteen hinta"}},[_c('Tag',{class:_vm.$style['icon']}),_vm._v(" "+_vm._s(new Intl.NumberFormat('fi-FI', { style: 'currency', currency: item.currency }).format((item.price * item.quantity) / 100) + (item.quantity > 1 ? (" (" + (item.quantity) + " kpl)") : ''))+" ")],1)]),_c('div',{class:_vm.$style['product-container'],staticStyle:{"height":"2rem","display":"inline-flex","margin-top":"-0.5rem"}},[_c('a',{class:_vm.$style['product-link'],staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"href":item.product_page,"target":"#","title":"Avaa tuotesivu"}},[_c('InformationVariant',{class:_vm.$style.icon}),_vm._v(" Tuotesivu ")],1),(item.product_id)?_c('button',{class:_vm.$style['product-link-orange'],staticStyle:{"width":"auto","font-weight":"normal","height":"2rem","text-transform":"unset"},attrs:{"title":"Lisää ostoskoriin"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('add-to-cart', {
                        product_name: item.product_name,
                        product_id: item.product_id,
                        variation_id: item.variation_id,
                        quantity: item.quantity
                      })}}},[_c('BasketPlus',{class:_vm.$style['icon-black'],attrs:{"viewBox":"0 0 24 24"}}),_vm._v(" Lisää ostoskoriin ")],1):_vm._e()])]),_c('span',{class:_vm.$style['product-description']},[_c('span',{},[_vm._v(_vm._s(item.product_description))])])])})],2)}),0):_vm._e(),_c('div',{class:_vm.$style.divider}),_c('div',{class:_vm.$style['store-link-container']},[_c('a',{class:_vm.$style['store-link'],attrs:{"href":_vm.link,"target":"#","title":"Avaa kauppasivu"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Kauppaan")]),_c('span',{class:_vm.$style['arrow-right-black']})]),_c('a',{class:_vm.$style['store-link'],attrs:{"href":_vm.link_to_cart,"target":"#","title":"Avaa ostoskori"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Ostoskoriin")]),_c('span',{class:_vm.$style['arrow-right-black']})])])]):(_vm.selected === 'second')?_c('div',{class:[_vm.$style.content]},[(_vm.vehicle_error)?_c('div',[(_vm.vehicle_error_status === 404)?_c('div',[_vm._v(" Valitettavasti emme löytäneet ajoneuvoa antamallasi rekisteritunnuksella. ")]):(_vm.vehicle_error_status === 429)?_c('div',[_vm._v(" Hakuraja saavutettu. Yritä myöhemmin uudelleen. ")]):_c('div',[_vm._v("Jokin meni pieleen. Yritä myöhemmin uudelleen.")])]):_vm._e(),(_vm.vehicle)?_c('div',_vm._l((Object.values(_vm.vehicle)),function(ref,i){
                      var description = ref.description;
                      var value = ref.value;
return _c('div',{key:("key-" + i)},[_c('div',{class:_vm.$style.description,attrs:{"title":description}},[_vm._v(" "+_vm._s(description)+" ")]),(value !== true && value !== false)?_c('div',{class:_vm.$style.value,staticStyle:{"color":"black"},attrs:{"title":value}},[_c('span',{class:_vm.$style.margin},[_vm._v(_vm._s(value))])]):_vm._e()])}),0):_vm._e()]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }