



































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import IconMotorcycle from '../assets/motorbike.vue'
import IconCar from '../assets/car-hatchback.vue'
import IconShoppingCart from '../assets/cart.vue'
import IconShoppingCartUp from '../assets/cart-up.vue'
import IconShoppingCartDown from '../assets/cart-down.vue'
import IconOpenInNew from '../assets/open.vue'
import Help from '../assets/help.vue'
import Information from '../assets/information.vue'
import InformationVariant from '../assets/information-variant.vue'
import Counter from '../assets/counter.vue'
import Tag from '../assets/tag.vue'
import Basket from '../assets/basket.vue'
import BasketPlus from '../assets/basket-plus.vue'

// @Component
@Component({
  components: {
    IconMotorcycle,
    IconCar,
    IconShoppingCart,
    IconShoppingCartUp,
    IconShoppingCartDown,
    IconOpenInNew,
    Help,
    Information,
    InformationVariant,
    Counter,
    Tag,
    Basket,
    BasketPlus
  }
})
export default class RvsTool extends Vue {
  car = true // true for cars, false for motorcycles

  registration = ''

  waiting = false

  products_error = false
  products_error_status: number | null = null
  vehicle_error = false
  vehicle_error_status: number | null = null

  vehicle = null
  products = null

  display = false
  selected = 'first'

  shake = false

  base_url = 'https://rvs.fi' // TODO: change for production

  link = `${this.base_url}/kauppa/`
  link_to_cart = `${this.base_url}/ostoskori/`

  async submit() {
    try {
      this.products = null
      this.vehicle = null
      this.products_error = false
      this.vehicle_error = false
      if (this.registration.length < 2) {
        this.shake = true
        setTimeout(() => {
          this.shake = false
        }, 500)
        return
      }
      this.waiting = true
      const products = await fetch(
        // `${process.env.VUE_APP_BASE_URL}/dummy/products/vehicle/${
        `${process.env.VUE_APP_BASE_URL}/products/vehicle/${this.registration}`
      )
      const vehicle = await fetch(
        // `${process.env.VUE_APP_BASE_URL}/dummy/vehicle/${
        `${process.env.VUE_APP_BASE_URL}/vehicle/${this.registration}`
      )
      if (products?.status === 200) {
        this.products = await products.json()

        this.link = (this.products as any)?.link?.value
        delete (this.products as any).link
      } else {
        this.products_error = true
        this.products_error_status = products.status
        this.link = `${this.base_url}/kauppa/`
      }
      if (vehicle?.status === 200) {
        this.vehicle = await vehicle.json()
      } else {
        this.vehicle_error = true
        this.vehicle_error_status = vehicle.status
        this.link = `${this.base_url}/kauppa/`
      }
      this.display = true
    } catch (err) {
      console.error(err)
    } finally {
      this.waiting = false
    }
  }
  clear() {
    this.display = false
  }
}
